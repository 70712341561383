import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';

const CARD_VARIANTS = {
  FEATURED: 'featured',
  HORIZONTAL: 'horizontal',
  SMALL: 'small',
};

const PostMeta = ({ post }) => (
  <div className="flex flex-wrap items-center gap-2 mt-2 text-sm text-neutral-7 sm:mt-0">
    {post.categories.nodes.map((cat, index) => (
      <React.Fragment key={cat.slug}>
        <p>{cat.name}</p>
        {index < post.categories.nodes.length - 1 && (
          <span className="text-neutral-7">|</span>
        )}
      </React.Fragment>
    ))}
    <p>{' | '}</p>
    <p>{post.modified}</p>
  </div>
);

PostMeta.propTypes = {
  post: PropTypes.shape({
    modified: PropTypes.string.isRequired,
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
const BlogCard = ({
  variant = CARD_VARIANTS.HORIZONTAL,
  post,
  className = '',
  imageClassName = '',
}) => {
  const renderFeatured = () => (
    <div className="h-full">
      <div>
        <GatsbyImage
          className={`overflow-clip ${imageClassName}`}
          image={post.featuredImage.node.gatsbyImage}
          alt={post.featuredImage.node.altText}
        />
        <div className="mt-5 space-y-2">
          <p className="sm:text-lg md:text-2xl md:leading-9 font-semiBold text-accent">
            {post.title}
          </p>
          <p
            className="hidden text-sm leading-6 sm:block"
            dangerouslySetInnerHTML={{ __html: post.excerpt }}
          />
          <PostMeta post={post} />
        </div>
      </div>
      <hr className="mt-6 text-primary-2 lg:hidden" />
    </div>
  );

  const renderHorizontal = () => (
    <div className="lg:h-full">
      <div className="grid w-full h-full grid-cols-1 gap-4 sm:grid-cols-12">
        <div className="flex flex-col order-2 sm:order-1 sm:col-span-7">
          <p className="flex-grow sm:text-lg md:text-xl font-semiBold text-accent">
            {post.title}
          </p>
          <PostMeta post={post} />
        </div>
        <div className="order-1 sm:order-2 sm:col-span-5">
          <GatsbyImage
            className={imageClassName}
            image={post.featuredImage.node.gatsbyImage}
            alt={post.featuredImage.node.altText}
          />
        </div>
      </div>
      <hr className="mt-6 text-primary-2 lg:hidden" />
    </div>
  );

  const renderSmall = () => (
    <div className="md:h-full">
      <div className="flex flex-col h-full gap-3">
        <GatsbyImage
          className={`aspect-video ${imageClassName}`}
          image={post.featuredImage.node.gatsbyImage}
          alt={post.featuredImage.node.altText}
        />
        <div className="flex flex-col justify-between flex-grow gap-2">
          <h3 className="flex-grow font-semibold sm:text-lg md:text-xl">
            {post.title}
          </h3>
          <PostMeta post={post} />
        </div>
      </div>
      <hr className="mt-6 text-primary-2 md:hidden" />
    </div>
  );

  const variantMap = {
    [CARD_VARIANTS.FEATURED]: renderFeatured,
    [CARD_VARIANTS.HORIZONTAL]: renderHorizontal,
    [CARD_VARIANTS.SMALL]: renderSmall,
  };

  return <div className={className}>{variantMap[variant]()}</div>;
};

BlogCard.propTypes = {
  variant: PropTypes.oneOf(Object.values(CARD_VARIANTS)),
  post: PropTypes.shape({
    title: PropTypes.string.isRequired,
    excerpt: PropTypes.string,
    modified: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        gatsbyImage: PropTypes.object.isRequired,
        altText: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
  className: PropTypes.string,
  imageClassName: PropTypes.string,
};

export { CARD_VARIANTS };
export default BlogCard;
