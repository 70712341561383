import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { DownloadButton, getDownloadMenuData } from './FeaturedAppCard';
import { Link, navigate } from 'gatsby';
import { Button } from './ui/Button';
import { preventEventBubbling } from '../utils';

const SecondaryAppCard = ({ app }) => {
  const previewImage = getImage(app.previewImageFile);
  const logo = getImage(app.appLogoImageFile);
  const dropdownMenuData = getDownloadMenuData(app.downloadLinks);

  const handleCardClick = (e) => {
    if (!e.defaultPrevented) {
      navigate(`/apps/${app.route}`);
    }
  };
  return (
    <div
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      className={`rounded-3xl mt-16 lg:mt-0 group p-6 xl:p-8 bg-neutral-3`}
    >
      <div
        className={`h-full flex flex-col items-center lg:grid lg:grid-cols-[148px_1fr] md:gap-6`}
      >
        <div className="w-[198px] lg:w-full -mt-20 lg:mt-0">
          <GatsbyImage
            image={previewImage}
            alt={app.appName}
            className="w-full h-auto"
          />
        </div>
        <div className="flex flex-col items-center w-full h-full text-center lg:text-start lg:items-start">
          <div className="hidden lg:block">
            <GatsbyImage image={logo} alt={app.appName} className="w-16 h-16" />
          </div>
          <h2
            className={`flex-grow leading-[34px] mt-4 mb-3 text-2xl ${
              app.font == 'bangla' ? 'font-bangla-semiBold' : 'font-semiBold'
            }`}
          >
            {app.appName}
          </h2>
          <div className="flex flex-col justify-center w-full gap-4 sm:flex-row md:flex-col lg:justify-start lg:flex-row ">
            <div className="md:w-full" onClick={preventEventBubbling}>
              <DownloadButton dropdownMenuData={dropdownMenuData} />
            </div>

            <Link to={app.route}>
              <Button className="w-full font-semiBold" variant="outline">
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

SecondaryAppCard.propTypes = {
  app: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    font: PropTypes.string,
    previewImageFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    route: PropTypes.string.isRequired,
    appLogoImageFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    downloadLinks: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

export default SecondaryAppCard;
