import * as React from 'react';
import PropTypes from 'prop-types';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown } from '../../custom-icons';
import { cn } from '../../utils';

const DropdownContext = React.createContext({
  isOpen: false,
  setIsOpen: () => null,
});

function Dropdown({ children, className }) {
  const [isOpen, setIsOpen] = React.useState(false);
  const dropdownRef = React.useRef(null);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContext.Provider value={{ isOpen, setIsOpen }}>
      <div
        ref={dropdownRef}
        className={cn('relative inline-block w-full', className)}
      >
        {children}
      </div>
    </DropdownContext.Provider>
  );
}

Dropdown.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function DropdownTrigger({ children, className }) {
  const { isOpen, setIsOpen } = React.useContext(DropdownContext);
  return (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className={cn(
        'items-center justify-center w-full gap-2  inline-flex whitespace-nowrap rounded-full ring-offset-white transition-all duration-300 ease-in-out shadow-none hover:shadow-lg focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-neutral-900 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
        className,
      )}
    >
      {children}
      <motion.div
        animate={{ rotate: isOpen ? 180 : 0 }}
        transition={{ duration: 0.2 }}
        className="flex items-center justify-center overflow-hidden"
      >
        <div className="relative inline-flex items-center justify-center w-6 h-6">
          <ChevronDown className="absolute w-6 h-6 transition-transform duration-200 -translate-y-6 group-hover:translate-y-0" />
          <ChevronDown className="absolute w-6 h-6 transition-transform duration-200 translate-y-0 group-hover:translate-y-6" />
        </div>
      </motion.div>
    </button>
  );
}

DropdownTrigger.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function DropdownContent({ children, className }) {
  const { isOpen } = React.useContext(DropdownContext);
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ duration: 0.2 }}
          className={cn(
            'absolute z-50 w-full mt-2 bg-neutral rounded-3xl shadow-lg py-6 overflow-hidden',
            className,
          )}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

DropdownContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

function DropdownItem({ children, className, onClick }) {
  const { setIsOpen } = React.useContext(DropdownContext);
  return (
    <motion.button
      onClick={() => {
        onClick?.();
        setIsOpen(false);
      }}
      className={cn(
        'w-full px-6 py-3 text-left text-[#2D2D2D] bg-neutral hover:bg-neutral-1',
        className,
      )}
    >
      {children}
    </motion.button>
  );
}

DropdownItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export { Dropdown, DropdownTrigger, DropdownContent, DropdownItem };
