import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import { CalenderIcon, ClockIcon } from '../custom-icons';

const CategoryButton = ({ slug, name }) => (
  <Link to={`/blog/category/${slug}`}>
    <motion.button
      className="px-5 py-1 rounded-lg bg-neutral-2 text-neutral-7 hover:shadow"
      whileHover={{ scale: 1.075 }}
      whileTap={{ scale: 0.9 }}
    >
      {name.charAt(0).toUpperCase() + name.slice(1)}
    </motion.button>
  </Link>
);

CategoryButton.propTypes = {
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

const ReadTimeDisplay = ({ timeToRead }) => {
  if (!timeToRead) return null;

  return (
    <div className="flex items-center justify-start gap-2 text-sm md:text-base">
      <ClockIcon className="text-neutral-7" />
      <p>{timeToRead} min read</p>
    </div>
  );
};

ReadTimeDisplay.propTypes = {
  timeToRead: PropTypes.number,
};

const PostMetadata = ({ categories, timeToRead, modified }) => (
  <div className="flex flex-wrap items-center justify-start gap-4 md:gap-6">
    {categories.nodes.map((cat) => (
      <CategoryButton key={cat.slug} {...cat} />
    ))}
    <ReadTimeDisplay timeToRead={timeToRead} />
    <div className="items-center justify-start hidden gap-2 text-sm sm:flex md:text-base">
      <CalenderIcon className="text-neutral-7" />
      <p>{modified}</p>
    </div>
  </div>
);

PostMetadata.propTypes = {
  categories: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
  timeToRead: PropTypes.number,
  modified: PropTypes.string.isRequired,
};
export default PostMetadata;
