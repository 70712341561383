import PropTypes from 'prop-types';
import React from 'react';
import DeprecatedAppCard from './DeprecatedAppCard';

const DeprecatedAppSection = ({ deprecatedApps }) => {
  return (
    <div className="max-w-6xl px-6 mx-auto md:px-4">
      <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-4">
        {deprecatedApps.map((app) => (
          <div key={app.id}>
            <DeprecatedAppCard app={app} />
          </div>
        ))}
      </div>
    </div>
  );
};

DeprecatedAppSection.propTypes = {
  deprecatedApps: PropTypes.array,
};
export default DeprecatedAppSection;
