import React from 'react';
import { Button } from './ui/Button';
import { ArrowRightIcon } from '../custom-icons';

const ProjectsCallout = () => {
  return (
    <section className="py-16 bg-primary-4">
      <div className="max-w-6xl px-6 mx-auto md:px-4">
        <p className="text-xl text-center font-semiBold md:text-start md:text-2xl text-neutral">
          Maybe it&apos;s a beginning of a beautiful friendship?
        </p>

        <h2 className="mt-3 text-[40px] leading-[48px] text-center md:text-start font-semiBold md:text-7xl">
          We Are Available For New Projects
        </h2>
        <a
          href="https://greentechapps.com/?utm_source=gtaf_website&utm_medium=cta&utm_content=home"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            variant="outline"
            className="w-full mt-6 space-x-2 border-0 bg-neutral group md:w-auto"
          >
            <p className="font-semiBold">Get In Touch</p>
            <ArrowRightIcon className="w-6 h-6 duration-200 transform group-hover:translate-x-1.5" />
          </Button>
        </a>
      </div>
    </section>
  );
};

export default ProjectsCallout;
