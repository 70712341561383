import { Link } from 'gatsby';
import React from 'react';

const DonateCallout = () => {
  return (
    <div className="w-full px-6">
      <section className="grid grid-cols-1 md:grid-cols-[1fr,auto] max-w-3xl m-auto  rounded-lg overflow-clip shadow-md">
        <div className="px-6 py-6 md:px-8 bg-neutral-9 w-gull">
          <h3 className="text-xl tracking-tight md:text-3xll text-neutral">
            To Be A Part Of This Sadaqa Jariah
          </h3>
        </div>
        <div>
          <Link to="/donate">
            <button className="w-full h-full px-12 py-6 font-semiBold text-neutral bg-secondary hover:bg-secondary-0 hover:">
              Donate
            </button>
          </Link>
        </div>
      </section>
    </div>
  );
};

export default DonateCallout;
