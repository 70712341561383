import React, { useState } from 'react';
import { ArrowRightIcon, CheckIcon, ChevronDown } from '../custom-icons';
import PropTypes from 'prop-types';

const DonationLoadingState = () => (
  <div className=" bg-neutral-0">
    <div className="cursor-progress animate-pulse">
      <div className="flex items-center p-4 border-b border-b-neutral-2">
        <span className="text-xl md:text-3xl">Choose amount</span>
      </div>
      <div id="donor-form-step-1">
        <div className="flex items-center gap-2 mx-auto mt-8 md:gap-4 w-min">
          <div className="px-6 py-4 border rounded-md md:px-10 md:py-6 whitespace-nowrap text-secondary border-secondary">
            One-time
          </div>
          <div className="px-6 py-4 rounded-md md:px-10 md:py-6 whitespace-nowrap bg-secondary text-neutral">
            Monthly
          </div>
        </div>

        <div className="mt-6">
          <div className="flex justify-between border-b border-b-neutral-2">
            <div>British Pound Sterling (GBP)</div>
            <ChevronDown className="text-neutral-7" />
          </div>
        </div>
        <div className="flex w-full gap-3 mt-4">
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>10</span>
          </div>
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>25</span>
          </div>
          <div className="flex-1 px-6 py-4 text-xl text-center border rounded-md border-neutral-5">
            <span>£</span>
            <span>100</span>
          </div>
        </div>
        <div className="flex-1 px-6 py-4 mt-4 border rounded-md border-neutral-5">
          <span className="ml-1 text-neutral-5">£</span>
          <span className="ml-1 text-neutral-5">Custom Amount</span>
        </div>
        <div className="mt-4">
          <label className="text-neutral-7">
            <input
              type="checkbox"
              name="leave_a_comment"
              id="leave_a_comment"
            />
            <span className="ml-1">Write us a comment</span>
          </label>
          <img
            alt="Gift Aid"
            src="https://donorbox.org/assets/gift_aid-3b0304178fcc1c1593d3d8d636ece3acd8d15323bc9004713cf307752a2ef788.png"
            width="100"
            className="mt-4"
          />
          <p className="mt-4">Boost your donations by 25% at no cost to you!</p>
        </div>
        <button
          className="flex justify-center w-full gap-2 py-4 my-6 rounded-md bg-secondary text-neutral "
          disabled
        >
          <p> Next </p>
          <ArrowRightIcon />
        </button>
      </div>
    </div>
  </div>
);
const DonationForm = ({ donorboxUrl }) => {
  const [isDonorboxLoading, setIsDonorboxLoading] = useState(true);

  return (
    <div className="text-center">
      {isDonorboxLoading && <DonationLoadingState />}
      {donorboxUrl && (
        <iframe
          className={`${isDonorboxLoading ? 'opacity-0' : ''} mt-8 `}
          // eslint-disable-next-line
          allowpaymentrequest=""
          frameBorder="0"
          name="donorbox"
          scrolling="no"
          seamless="seamless"
          src={donorboxUrl}
          style={{
            maxHeight: 'none!important',
          }}
          width="100%"
          onLoad={() => {
            setIsDonorboxLoading(false);
          }}
        ></iframe>
      )}

      <div className="flex flex-col justify-start gap-3 p-3 text-xs lg:gap-6 lg:flex-row bg-primary-2 rounded-xl text-neutral-7">
        <div className="flex items-center w-max">
          <CheckIcon className="text-primary-4" />
          <p>Quarterly & Annual transparency reports</p>
        </div>
        <div className="flex items-center w-max">
          <CheckIcon className="text-primary-4" />
          <p>Cancel / Edit anytime</p>
        </div>
        <div className="flex items-center w-max">
          <CheckIcon className="text-primary-4" />
          <p>Giftaid (UK only)</p>
        </div>
      </div>
    </div>
  );
};

DonationForm.propTypes = {
  donorboxUrl: PropTypes.string,
};

export default DonationForm;
