require('./src/styles/global.css');
import React from 'react';
import { ToastProvider } from './src/contexts/ToastContext';
const addScript = (url, paypalExpress = null, defer = false) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  if (paypalExpress) {
    script.paypalExpress = paypalExpress;
  }
  if (defer) {
    script.defer = true;
  }
  document.body.appendChild(script);
};

const addCustomScript = (content) => {
  const script = document.createElement('script');
  script.innerHTML = content;
  document.body.appendChild(script);
};

const addCustomNoScript = (content) => {
  const script = document.createElement('noscript');
  script.innerHTML = content;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript('/js/donorbox-widget.js', true);

    addScript('https://www.googletagmanager.com/gtag/js?id=G-Z9C1RKZM16');
    addCustomScript(`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'G-Z9C1RKZM16');`);

    addScript('https://www.googletagmanager.com/gtag/js?id=AW-804925509');
    addCustomScript(`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments); }
      gtag('js', new Date());
      gtag('config', 'AW-804925509');`);

    addCustomScript(`!function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      }(window,document,'script','dataLayer','GTM-P3NWLD3');`);

    addCustomScript(`
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '895770941489500');
      fbq('track', 'PageView');`);

    addCustomNoScript(`
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=895770941489500&ev=PageView&noscript=1"
      />
    `);

    addCustomNoScript(`
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-P3NWLD3"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `);

    addScript('https://donorbox.org/install-popup-button.js', null, true);
    addCustomScript(
      "window.DonorBox = { widgetLinkClassName: 'dbox-donation-button' };",
    );
  };
};

export const wrapRootElement = ({ element }) => (
  <ToastProvider>{element}</ToastProvider>
);
