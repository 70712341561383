import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  Dropdown,
  DropdownContent,
  DropdownItem,
  DropdownTrigger,
} from './ui/Dropdown';
import { appStoreNames, fadeUpVariants } from '../constants';
import FeaturedAppsCardBg from '../../static/images/featured-apps-card-bg-icon.svg';
import { Link, navigate } from 'gatsby';
import { Button } from './ui/Button';
import { preventEventBubbling } from '../utils';

export const getDownloadMenuData = (downloadLinks) => {
  const menuData = [];
  Object.keys(downloadLinks).forEach((key) => {
    if (downloadLinks[key] !== null) {
      menuData.push({
        route: downloadLinks[key],
        title: appStoreNames.find((item) => item.slug === key).label,
      });
    }
  });
  return menuData;
};

export const DownloadButton = ({ dropdownMenuData }) => (
  <Dropdown className="w-full sm:w-max md:w-full lg:w-max">
    <DropdownTrigger className="w-full px-5 py-3 rounded-full font-semiBold bg-primary text-neutral lg:w-max">
      Download
    </DropdownTrigger>
    <DropdownContent className="w-60">
      {dropdownMenuData.map((item) => (
        <a
          key={item.title}
          href={item.route}
          target="_blank"
          rel="noreferrer"
          onClick={(e) => e.stopPropagation()}
        >
          <DropdownItem className="font-regular">{item.title}</DropdownItem>
        </a>
      ))}
    </DropdownContent>
  </Dropdown>
);

const APP_THEMES = {
  quran: {
    bg: 'bg-[#25441C]/90',
  },
  hadith: {
    bg: 'bg-[#12417A]/90',
  },
  sadiq: {
    bg: 'bg-[#1F4F51]/90',
  },
};

export const CleanFeaturedCard = ({ app }) => {
  const image = getImage(app.previewImageFile);
  const dropdownMenuData = getDownloadMenuData(app.downloadLinks);

  return (
    <Link to={`/apps/${app.route}`}>
      <motion.div
        className="relative flex flex-col items-center mt-16 bg-neutral-3 rounded-3xl group"
        custom={0}
        variants={fadeUpVariants}
      >
        <motion.div
          className="w-[198px] -mt-14"
          custom={1}
          variants={fadeUpVariants}
        >
          <GatsbyImage
            image={image}
            alt={app.appName}
            className="w-full h-auto"
          />
        </motion.div>
        <div className="flex flex-col items-center px-6 py-8 space-y-4 text-center sm:px-10">
          <motion.h2
            className="text-2xl font-bold text-accent leading-[34px] auto"
            custom={2}
            variants={fadeUpVariants}
          >
            {app.appName}
          </motion.h2>
          <motion.div
            custom={3}
            variants={fadeUpVariants}
            className="w-full"
            onClick={preventEventBubbling}
          >
            <DownloadButton dropdownMenuData={dropdownMenuData} />
          </motion.div>
        </div>
      </motion.div>
    </Link>
  );
};

export const ModernFeaturedCard = ({ app, index }) => {
  const image = getImage(app.previewImageFile);
  const logo = getImage(app.appLogoImageFile);
  const dropdownMenuData = getDownloadMenuData(app.downloadLinks);
  const theme = APP_THEMES[app?.route] || APP_THEMES.quran;

  const handleCardClick = (e) => {
    if (!e.defaultPrevented) {
      navigate(`/apps/${app.route}`);
    }
  };
  return (
    <div
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      className={`relative p-6 md:p-8 lg:p-10 mt-24 md:mt-36 rounded-4xl ${theme.bg} group`}
    >
      <div className="absolute inset-0 overflow-hidden rounded-4xl">
        <div className="absolute inset-0 -z-10">
          <img
            src={FeaturedAppsCardBg}
            alt=""
            className="absolute inset-0 object-cover w-full h-full -z-10"
          />
        </div>
      </div>

      <div
        className={`relative flex flex-col items-center md:grid ${
          index % 2 === 0
            ? 'md:grid-cols-[247px_1fr]'
            : 'md:grid-cols-[1fr_247px]'
        } md:gap-6`}
      >
        <motion.div
          className={`w-[198px] md:w-full -mt-20 md:-mt-28 ${
            index % 2 === 1 ? 'md:order-2' : ''
          }`}
          custom={1}
          variants={fadeUpVariants}
        >
          <GatsbyImage
            image={image}
            alt={app.appName}
            className="w-full h-auto"
          />
        </motion.div>

        <div className="flex flex-col items-center h-full text-center md:text-start md:items-start text-neutral font-semiBold">
          <motion.div
            custom={2}
            variants={fadeUpVariants}
            className="hidden md:block"
          >
            <GatsbyImage image={logo} alt={app.appName} className="w-16 h-16" />
          </motion.div>
          <div className="flex-1 lg:flex-grow-0">
            <motion.h2
              custom={3}
              variants={fadeUpVariants}
              className="mt-4 mb-3 text-2xl md:text-3xl"
            >
              {app.appName}
            </motion.h2>
            <div className="hidden md:block">
              <hr className="border-t-[3px] text-neutral-5 w-60" />
            </div>
          </div>
          <div
            className="text-[42px] leading-[58.8px] tracking-tight mt-5 max-w-2xl flex-grow  hidden lg:block"
            dangerouslySetInnerHTML={{ __html: app.description }}
          />

          <div className="flex flex-col justify-center w-full gap-4 sm:w-auto sm:flex-row md:justify-start">
            <div onClick={preventEventBubbling}>
              <DownloadButton dropdownMenuData={dropdownMenuData} />
            </div>

            <Link to={app.route}>
              <Button className="w-full" variant="outline">
                Learn More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const commonPropTypes = {
  app: PropTypes.shape({
    appName: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    previewImageFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
    downloadLinks: PropTypes.objectOf(PropTypes.string),
  }).isRequired,
};

const commonDefaultProps = {
  app: {
    downloadLinks: {},
  },
};
DownloadButton.propTypes = {
  dropdownMenuData: PropTypes.arrayOf(
    PropTypes.shape({
      route: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

CleanFeaturedCard.propTypes = commonPropTypes;
CleanFeaturedCard.defaultProps = commonDefaultProps;

ModernFeaturedCard.propTypes = {
  ...commonPropTypes,
  app: PropTypes.shape({
    ...commonPropTypes.app.shape,
    description: PropTypes.string.isRequired,
    appLogoImageFile: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

ModernFeaturedCard.defaultProps = commonDefaultProps;
