import PropTypes from 'prop-types';
import React from 'react';
import SecondaryAppCard from './SecondaryAppCard';

const SecondaryAppsSection = ({ secondaryApps }) => {
  return (
    <div className="max-w-6xl px-6 mx-auto md:px-4">
      <div className="grid grid-cols-1 gap-8 lg:gap-8 md:grid-cols-2">
        {secondaryApps.map((app) => {
          return <SecondaryAppCard key={app.id} app={app} />;
        })}
      </div>
    </div>
  );
};

SecondaryAppsSection.propTypes = {
  secondaryApps: PropTypes.arrayOf(PropTypes.object),
};
export default SecondaryAppsSection;
