import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import Layout from '../../components/layout';
import { CommonContext } from '../../context';
import { ShowcaseFeaturedSection } from '../../components/FeaturedAppsSection';
import SecondaryAppsSection from '../../components/SecondaryAppsSection';
import DeprecatedAppSection from '../../components/DeprecatedAppSection';
import { fadeUpVariants } from '../../constants';
import DonateCallout from '../../components/DonateCallout';

export const query = graphql`
  query allAppListQuery {
    allAppList {
      nodes {
        id
        isFeatured
        appName
        route
        description
        projectType
        font
        theme
        appLogoImageFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        previewImageFile {
          childImageSharp {
            gatsbyImageData(
              placeholder: NONE
              layout: CONSTRAINED
              quality: 100
              width: 247
            )
          }
        }
        heroImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        listImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        smallImageFile {
          childImageSharp {
            gatsbyImageData(width: 700, placeholder: NONE, layout: CONSTRAINED)
          }
        }
        downloadLinks {
          playStore
          huawei
          appStore
          mac
          windows
          web
        }
      }
    }
  }
`;

function OurApps(props) {
  const appsList = props.data.allAppList.nodes;

  const featuredApps = appsList.filter(
    (item) => item.projectType === 'Featured',
  );
  const secondaryApps = appsList.filter(
    (item) => item.projectType === 'Secondary',
  );
  const deprecatedApps = appsList.filter(
    (item) => item.projectType === 'Deprecated',
  );
  const topApp = featuredApps[0];

  return (
    <CommonContext.Provider value={{ location: props.location }}>
      <Layout
        title="Our Apps"
        image={topApp.smallImageFile.url}
        location={props.location}
      >
        <motion.section
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          custom={0}
          variants={fadeUpVariants}
          className="max-w-6xl px-6 mx-auto mt-16 text-center md:px-4"
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl">
            Discover Our Apps to help you{' '}
            <span className="font-semiBold">Understand</span> Islam
          </h1>
        </motion.section>
        <section className="mt-24 md:mt-48">
          <ShowcaseFeaturedSection apps={featuredApps} />
        </section>
        <section className="mt-24 md:mt-36">
          <SecondaryAppsSection secondaryApps={secondaryApps} />
        </section>
        <section className="mt-24">
          <DeprecatedAppSection deprecatedApps={deprecatedApps} />
        </section>
        <section className="my-24">
          <DonateCallout />
        </section>
      </Layout>
    </CommonContext.Provider>
  );
}

OurApps.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default OurApps;
