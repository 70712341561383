import PropTypes from 'prop-types';
import React, { useState, memo, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { PaginationArrowLeft, PaginationArrowRight } from '../custom-icons';

const DEFAULT_CATEGORY = { slug: 'all-blogs', name: 'All' };

const TabButton = memo(({ category, isActive, onClick }) => (
  <motion.button
    onClick={() => onClick(category.slug)}
    className={`relative rounded-full px-4 py-2 text-sm font-medium whitespace-nowrap h-[40px] shadow-neutral
      ${
        isActive
          ? 'text-neutral-0 bg-primary-900 md:bg-transparent'
          : 'bg-neutral-3'
      }`}
    whileHover={{ scale: 1.1 }}
  >
    {isActive && (
      <motion.span
        layoutId="bubble"
        className="absolute inset-0 z-10 hidden rounded-full bg-primary-900 md:block"
      />
    )}
    <span className="relative z-20">{category.name}</span>
  </motion.button>
));

TabButton.displayName = 'TabButton';
TabButton.propTypes = {
  category: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ScrollButton = memo(({ show, onClick, direction }) => (
  <AnimatePresence>
    {show && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className={`absolute ${
          direction === 'left' ? 'left-0' : 'right-0'
        } top-1/2 z-20 -translate-y-1/2`}
      >
        <div className="relative">
          <div className="absolute transition-opacity duration-500 rounded-full -inset-5 bg-neutral blur-md" />
          <motion.button
            whileHover={{ scale: 1.05 }}
            onClick={onClick}
            className="relative flex items-center justify-center w-12 h-12 p-2 rounded-full bg-neutral-3"
          >
            {direction === 'left' ? (
              <PaginationArrowLeft className="w-6 h-6 text-accent" />
            ) : (
              <PaginationArrowRight className="w-6 h-6 text-accent" />
            )}
          </motion.button>
        </div>
      </motion.div>
    )}
  </AnimatePresence>
));

ScrollButton.displayName = 'ScrollButton';
ScrollButton.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  direction: PropTypes.oneOf(['left', 'right']).isRequired,
};

const BlogCategoryTab = memo(({ activeCategory }) => {
  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  const data = useStaticQuery(graphql`
    query CategoryQuery {
      allWpCategory {
        nodes {
          slug
          name
        }
      }
    }
  `);

  const allCategories = [DEFAULT_CATEGORY, ...data.allWpCategory.nodes];

  const checkForOverflow = () => {
    if (containerRef.current) {
      const { scrollWidth, clientWidth, scrollLeft } = containerRef.current;
      setShowLeftButton(scrollLeft > 0);
      setShowRightButton(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  useEffect(() => {
    checkForOverflow();
    const observer = new ResizeObserver(checkForOverflow);
    if (containerRef.current) {
      observer.observe(containerRef.current);
      containerRef.current.addEventListener('scroll', checkForOverflow);
    }

    return () => {
      observer.disconnect();
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', checkForOverflow);
      }
    };
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const activeTab = containerRef.current.querySelector(
        `[data-category="${activeCategory.slug}"]`,
      );
      if (activeTab) {
        const container = containerRef.current;
        const scrollLeft =
          activeTab.offsetLeft -
          (container.clientWidth - activeTab.offsetWidth) / 2;
        container.scrollTo({ left: scrollLeft, behavior: 'instant' });
      }
    }
  }, [activeCategory]);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const scrollAmount = containerRef.current.clientWidth * 0.75;
      containerRef.current.scrollBy({
        left: direction === 'left' ? -scrollAmount : scrollAmount,
        behavior: 'smooth',
      });
    }
  };

  const handleTabClick = (categorySlug) => {
    navigate(
      categorySlug === 'all-blogs'
        ? '/blog/'
        : `/blog/category/${categorySlug}/`,
    );
  };

  return (
    <div className="relative w-full bg-neutral-900 rounded-xl">
      <div className="w-full overflow-hidden">
        <div
          ref={containerRef}
          className="flex items-center gap-3 px-6 mx-3 overflow-hidden scroll-smooth no-scrollbar md:gap-3 h-14"
        >
          {allCategories.map((category) => (
            <motion.div key={category.slug} data-category={category.slug}>
              <TabButton
                category={category}
                isActive={category.slug === activeCategory.slug}
                onClick={handleTabClick}
              />
            </motion.div>
          ))}
        </div>
      </div>

      <ScrollButton
        direction="left"
        onClick={() => handleScroll('left')}
        show={showLeftButton}
      />
      <ScrollButton
        direction="right"
        onClick={() => handleScroll('right')}
        show={showRightButton}
      />
    </div>
  );
});

BlogCategoryTab.displayName = 'BlogCategoryTab';
BlogCategoryTab.propTypes = {
  activeCategory: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default BlogCategoryTab;
