import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import BoldTitle from './BoldTitle';
import BlogCard, { CARD_VARIANTS } from './BlogCard';

function BlogHighlights({ title, featuredBlogs }) {
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-6xl px-6 mb-16 md:px-4">
        <BoldTitle className="mt-16 mb-8 md:mt-24 md:mb-16 md:text-center">
          {title}
        </BoldTitle>
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {featuredBlogs.map((post) => {
            return (
              <Link to={`/blog/${post.slug}/`} key={post.slug}>
                <BlogCard
                  className="w-full h-full"
                  variant={CARD_VARIANTS.SMALL}
                  post={post}
                />
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}

BlogHighlights.propTypes = {
  title: PropTypes.string,
  count: PropTypes.number,
  featuredBlogs: PropTypes.array.isRequired,
};

BlogHighlights.defaultProps = {
  title: 'From Our Blog',
  count: 3,
};

export default BlogHighlights;
