import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import BlogCard, { CARD_VARIANTS } from './BlogCard';
import { CommonContext } from '../context';
import Layout from './layout';
import BlogCategoryTab from './BlogCategoryTab';
import FeaturedPosts from './FeaturedPosts';
import BlogCategoryBanner from './BlogCategoryBanner';

const POSTS_PER_PAGE = 6;
const FEATURED_POSTS_COUNT = 4;

const BlogGrid = ({ posts }) => (
  <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
    {posts.map((post) => (
      <Link to={`/blog/${post.slug}/`} key={post.slug}>
        <BlogCard
          className="w-full h-full"
          variant={CARD_VARIANTS.SMALL}
          post={post}
        />
      </Link>
    ))}
  </div>
);

const PaginationButtons = ({
  canShowLess,
  canShowMore,
  onShowLess,
  onShowMore,
}) => (
  <div className="mt-16 space-x-4 text-center">
    {canShowLess && (
      <button
        className="px-8 py-4 font-semibold transition-all duration-300 border rounded-lg border-primary text-primary hover:bg-primary-0 hover:shadow-xl"
        onClick={onShowLess}
      >
        Show Less
      </button>
    )}
    {canShowMore && (
      <button
        className="px-8 py-4 font-semibold transition-all duration-300 border rounded-lg border-primary text-primary hover:bg-primary-0 hover:shadow-xl"
        onClick={onShowMore}
      >
        Show More
      </button>
    )}
  </div>
);

const BaseBlogsLayout = ({
  title,
  description,
  location,
  blogData,
  categoryBannerImage,
  bannerLinkUrl,
  activeCategory,
  allCategories,
  pageTitle,
  children,
  categoryBannerImageAltText,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const featuredPosts = blogData.slice(0, FEATURED_POSTS_COUNT);
  const remainingPosts = blogData.slice(FEATURED_POSTS_COUNT);

  const totalPages = Math.ceil(remainingPosts.length / POSTS_PER_PAGE);
  const postsToDisplay = remainingPosts.slice(0, currentPage * POSTS_PER_PAGE);

  const canShowMore = currentPage < totalPages;
  const canShowLess = currentPage > 1;

  const handleShowMore = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  };

  const handleShowLess = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
  };

  const hasCategoryBanner = !!categoryBannerImage;

  return (
    <CommonContext.Provider value={{ location }}>
      <Layout title={title} description={description} location={location}>
        <div className="w-full max-w-6xl px-6 m-auto my-4 md:my-8 md:px-4 bg-neutral">
          <BlogCategoryTab
            allCategories={allCategories}
            activeCategory={activeCategory}
          />

          <div className="mt-5 mb-6 text-3xl font-bold text-center md:mt-12 md:mb-10 md:text-5xl text-accent">
            {pageTitle}
          </div>

          <FeaturedPosts featuredPosts={featuredPosts} />

          <div className={hasCategoryBanner ? 'my-16 sm:mt-24 md:mt-48' : ''}>
            {hasCategoryBanner && (
              <BlogCategoryBanner
                bannerLinkUrl={bannerLinkUrl}
                categoryBannerImage={categoryBannerImage}
                altText={categoryBannerImageAltText}
              />
            )}
          </div>

          <div
            className={hasCategoryBanner ? 'mb-24 sm:mt-24 md:mt-48' : 'my-16'}
          >
            <BlogGrid posts={postsToDisplay} />
            <PaginationButtons
              canShowLess={canShowLess}
              canShowMore={canShowMore}
              onShowLess={handleShowLess}
              onShowMore={handleShowMore}
            />
          </div>

          {children}
        </div>
      </Layout>
    </CommonContext.Provider>
  );
};

export const PostPropType = PropTypes.shape({
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  modified: PropTypes.string.isRequired,
  featuredImage: PropTypes.shape({
    node: PropTypes.shape({
      altText: PropTypes.string,
      gatsbyImage: PropTypes.object,
    }).isRequired,
  }).isRequired,
  postMetadata: PropTypes.shape({
    order: PropTypes.number,
    featured: PropTypes.bool,
  }),
  categories: PropTypes.shape({
    nodes: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }),
});

BaseBlogsLayout.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  location: PropTypes.object.isRequired,
  blogData: PropTypes.arrayOf(PostPropType).isRequired,
  categoryBannerImage: PropTypes.object,
  categoryBannerImageAltText: PropTypes.string,
  bannerLinkUrl: PropTypes.string,
  activeCategory: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  allCategories: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  pageTitle: PropTypes.string.isRequired,
  children: PropTypes.node,
};

BlogGrid.propTypes = {
  posts: PropTypes.arrayOf(PostPropType).isRequired,
};

PaginationButtons.propTypes = {
  canShowLess: PropTypes.bool.isRequired,
  canShowMore: PropTypes.bool.isRequired,
  onShowLess: PropTypes.func.isRequired,
  onShowMore: PropTypes.func.isRequired,
};

export default BaseBlogsLayout;
