import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import PropTypes from 'prop-types';
import { motion, useAnimation } from 'framer-motion';
import HeroSection from '../components/HeroSection';
import { CommonContext } from '../context';
import { fadeUpVariants } from '../constants';
import { GridFeaturedSection } from '../components/FeaturedAppsSection';
import Reviews from '../components/Reviews';
import { HomePageReviewTitle } from '../components/ReviewTitle';
import ProjectsCallout from '../components/ProjectsCallout';
import DonateCallout from '../components/DonateCallout';
import BlogHighlights from '../components/BlogHighlights';
import AchievementsSection from '../components/AchievementsSection';

export const query = graphql`
  query homePageDataQuery {
    allAppList(filter: { isFeatured: { eq: true } }) {
      nodes {
        id
        isFeatured
        appName
        description
        theme
        route
        font
        previewImageFile {
          childImageSharp {
            gatsbyImageData(placeholder: NONE, layout: CONSTRAINED)
          }
        }
        downloadLinks {
          playStore
          huawei
          appStore
          mac
          windows
          web
          linux
        }
      }
    }
    homePageBannerImage: file(relativePath: { eq: "home-page-banner.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 480
          placeholder: BLURRED
          layout: CONSTRAINED
          quality: 100
        )
      }
    }
    allWpPost(sort: { fields: modified, order: DESC }, limit: 3) {
      nodes {
        slug
        title
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
    allAchievements {
      nodes {
        icon
        title
        description
      }
    }
    allUserReviews {
      nodes {
        id
        reviewer
        stars
        reviewText
        avatar
      }
    }
  }
`;

function IndexPage({ data, location }) {
  const achievements = data.allAchievements.nodes;
  const featuredApps = data.allAppList.nodes;
  const homePageBannerImage =
    data.homePageBannerImage.childImageSharp.gatsbyImageData;
  const reviews = data.allUserReviews.nodes;
  const featuredBlogs = data.allWpPost.nodes;

  const achievementsControls = useAnimation();

  const onHeroAnimationStart = () => {
    setTimeout(() => {
      achievementsControls.start('visible');
    }, 600);
  };
  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        keywords={[`greentech`, `islam`, `non-profit`, `muslims`]}
        title="Home"
        location={location}
      >
        <HeroSection
          homePageBannerImage={homePageBannerImage}
          onAnimationStart={onHeroAnimationStart}
        />
        <motion.div
          initial="hidden"
          animate={achievementsControls}
          variants={fadeUpVariants}
        >
          <AchievementsSection achievements={achievements} title="Our Impact" />
        </motion.div>
        <section className="mt-24 mb-16 md:mb-24 md:mt-40">
          <GridFeaturedSection apps={featuredApps} />
        </section>
        <section>
          {reviews.length ? (
            <Reviews reviews={reviews}>
              <HomePageReviewTitle />
            </Reviews>
          ) : null}
        </section>
        <section className="md:mt-24">
          <DonateCallout />
        </section>
        <section className="mt-16 md:mt-40">
          <ProjectsCallout />
        </section>
        <section className="md:mt-16">
          <BlogHighlights
            title="Explore More Inspiring Reads"
            count={3}
            featuredBlogs={featuredBlogs}
          />
        </section>
      </Layout>
    </CommonContext.Provider>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default IndexPage;
