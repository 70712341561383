import { Link, navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from './ui/Button';

const DeprecatedAppCard = ({ app }) => {
  const logo = getImage(app.appLogoImageFile);

  const handleCardClick = (e) => {
    if (!e.defaultPrevented) {
      navigate(`/apps/${app.route}`);
    }
  };
  return (
    <div
      onClick={handleCardClick}
      role="button"
      tabIndex={0}
      className="flex flex-col items-center justify-center h-full gap-5 p-8 border border-primary-3 rounded-2xl"
    >
      <div>
        <GatsbyImage image={logo} alt={app.appName} className="w-16 h-16" />
      </div>
      <h2
        className={`flex-grow text-xl text-center ${
          app.font == 'bangla' ? 'font-bangla-semiBold' : 'font-semiBold'
        }`}
      >
        {app.appName}
      </h2>
      <Link to={app.route} className="w-full sm:w-auto">
        <Button className="w-full font-semiBold sm:w-auto" variant="outline">
          Learn More
        </Button>
      </Link>
    </div>
  );
};
DeprecatedAppCard.propTypes = {
  app: PropTypes.object,
};
export default DeprecatedAppCard;
