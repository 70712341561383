import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

const BlogCategoryBanner = ({
  bannerLinkUrl,
  categoryBannerImage,
  altText,
}) => {
  return (
    <div>
      {bannerLinkUrl ? (
        <a
          href={bannerLinkUrl}
          target="_blank"
          rel="noreferrer"
          className="w-full h-full"
        >
          <GatsbyImage
            className="w-full"
            image={categoryBannerImage}
            alt={altText}
          />
        </a>
      ) : (
        <GatsbyImage
          className="w-full"
          image={categoryBannerImage}
          alt={altText}
        />
      )}
    </div>
  );
};

export default BlogCategoryBanner;

BlogCategoryBanner.propTypes = {
  bannerLinkUrl: PropTypes.string,
  categoryBannerImage: PropTypes.object,
  altText: PropTypes.string,
};
