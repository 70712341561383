import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import { CleanFeaturedCard, ModernFeaturedCard } from './FeaturedAppCard';
import { fadeUpVariants } from '../constants';

export const GridFeaturedSection = ({ apps }) => {
  return (
    <section className="w-full max-w-6xl px-6 m-auto space-y-8 md:space-y-20 md:px-4">
      <motion.h3
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeUpVariants}
        custom={0}
        className="text-3xl font-bold text-center md:text-5xl text-accent"
      >
        Supporting your Learning Journey
      </motion.h3>

      <motion.div
        className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={fadeUpVariants}
        custom={0}
      >
        {apps.map((app) => (
          <motion.div key={app.id}>
            <CleanFeaturedCard app={app} />
          </motion.div>
        ))}
      </motion.div>
    </section>
  );
};

export const ShowcaseFeaturedSection = ({ apps }) => {
  return (
    <section className="w-full max-w-6xl px-6 m-auto space-y-36 md:space-y-36 md:px-4">
      <motion.div>
        {apps.map((app, i) => {
          return (
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.05 }}
              variants={fadeUpVariants}
              custom={1}
              key={app.id}
            >
              <ModernFeaturedCard app={app} index={i} />
            </motion.div>
          );
        })}
      </motion.div>
    </section>
  );
};

const appPropType = PropTypes.shape({
  id: PropTypes.string.isRequired,
  appName: PropTypes.string.isRequired,
  previewImageFile: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
  downloadLinks: PropTypes.objectOf(PropTypes.string),
});

GridFeaturedSection.propTypes = {
  apps: PropTypes.arrayOf(appPropType).isRequired,
};

ShowcaseFeaturedSection.propTypes = {
  apps: PropTypes.arrayOf(appPropType).isRequired,
};
