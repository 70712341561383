import { graphql } from 'gatsby';
import React from 'react';
import { getImage } from 'gatsby-plugin-image';
import { sortBlogPosts } from '../../utils';
import PropTypes from 'prop-types';
import BaseBlogsLayout, {
  PostPropType,
} from '../../components/BaseBlogsLayout';

const DEFAULT_CATEGORY = { slug: 'all-blogs', name: 'All' };

export const BLOGS_QUERY = graphql`
  query allBlogsQuery {
    allWpCategory {
      nodes {
        slug
        name
        categoryBannerImages {
          bannerLinkUrl
          categoryBannerImage {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
      }
    }
    allWpPost(
      sort: { fields: modified, order: DESC }
      filter: { featuredImageId: { ne: null } }
    ) {
      nodes {
        slug
        title
        excerpt
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        postMetadata {
          order
          featured
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
    quranDownloadBanner: file(
      relativePath: { eq: "Quran-app-download-banner.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, width: 1000, placeholder: BLURRED)
      }
    }
  }
`;

const AllBlogs = ({ data, location }) => {
  const categoryBannerImage = getImage(data.quranDownloadBanner);
  const blogData = sortBlogPosts(data.allWpPost.nodes);

  return (
    <BaseBlogsLayout
      title="Blog"
      description="Discover latest news and articles from Greentech Apps Foundation"
      location={location}
      blogData={blogData}
      categoryBannerImage={categoryBannerImage}
      categoryBannerImageAltText="Quran app download banner"
      activeCategory={DEFAULT_CATEGORY}
      pageTitle="Recent Blogs"
      bannerLinkUrl="/apps/quran/"
    />
  );
};

AllBlogs.propTypes = {
  data: PropTypes.shape({
    allWpCategory: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          categoryBannerImages: PropTypes.shape({
            bannerLinkUrl: PropTypes.string,
            categoryBannerImage: PropTypes.shape({
              altText: PropTypes.string,
              gatsbyImage: PropTypes.object,
            }),
          }),
        }),
      ).isRequired,
    }).isRequired,
    allWpPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(PostPropType).isRequired,
    }).isRequired,
    quranDownloadBanner: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default AllBlogs;
