import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Button } from './ui/Button';
import { motion } from 'framer-motion';
import { PlaySharpIcon } from '../custom-icons';
import { Modal } from './modal';
import { Link } from 'gatsby';
import Wave from './ui/Wave';
import { fadeUpVariants } from '../constants';

const HeroSection = ({ homePageBannerImage, onAnimationStart }) => {
  const [play, setPlay] = useState(false);
  const url = play
    ? `https://www.youtube.com/embed/lHnYyw18vDs?si=b7nDyoEiUl8rTt4b&autoplay=1`
    : `https://www.youtube.com/embed/lHnYyw18vDs?si=b7nDyoEiUl8rTt4b`;

  return (
    <motion.section
      initial="hidden"
      animate="visible"
      onAnimationStart={onAnimationStart}
      className="flex flex-col items-center max-w-6xl gap-12 px-6 m-auto my-16 md:gap-16 md:mb-40 md:mt-24 lg:grid sm:px-4"
    >
      <div className="flex flex-col items-center justify-between h-full col-start-1 lg:items-start">
        <motion.h1
          custom={1}
          variants={fadeUpVariants}
          className="mb-auto text-3xl text-center font-semiBold lg:text-7xl lg:text-left"
        >
          Helping Muslims Better Understand Islam
        </motion.h1>
        <motion.div
          custom={2}
          variants={fadeUpVariants}
          className="flex flex-col justify-center w-full gap-4 mt-6 lg:justify-start sm:flex-row"
        >
          <Link to="/apps">
            <Button className="w-full font-semiBold">
              Explore Our Projects
            </Button>
          </Link>
          <Link to="/about">
            <Button className="w-full font-semiBold" variant="outline">
              Learn More
            </Button>
          </Link>
        </motion.div>
      </div>
      <motion.div className="relative col-start-2 lg:w-[480px]">
        <motion.div
          custom={3}
          variants={fadeUpVariants}
          className="relative lg:aspect-[16/9] w-full rounded-lg overflow-clip"
        >
          <GatsbyImage
            image={homePageBannerImage}
            alt="How Our Apps Enhance Daily Worship & Bring You Closer to Allah"
          />
          <div className="absolute inset-0 bg-black/40" />
          <div className="absolute inset-0 z-10 flex items-center justify-center">
            <div className="relative flex w-max h-max">
              {[0, 1, 2].map((_, index) => (
                <Wave key={index} delay={index * 0.6} />
              ))}
              <motion.button
                className="relative z-10 rounded-full shadow-xl w-14 h-14 lg:w-24 lg:h-24 bg-neutral/20 dark:bg-neutral-800"
                onClick={() => setPlay(!play)}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ type: 'spring', stiffness: 400, damping: 17 }}
              >
                <PlaySharpIcon className="w-6 h-6 m-auto sm:w-8 sm:h-6 lg:w-9 lg:h-9 text-neutral" />
              </motion.button>
            </div>
          </div>
        </motion.div>
        {play && (
          <Modal
            isOpen={play}
            onClose={() => setPlay(false)}
            className="max-w-3xl"
          >
            <iframe
              className="w-full border-0 rounded-lg aspect-video"
              src={url}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowfullscreen="allowfullscreen"
            />
          </Modal>
        )}
      </motion.div>
    </motion.section>
  );
};

HeroSection.propTypes = {
  homePageBannerImage: PropTypes.object.isRequired,
  onAnimationStart: PropTypes.func,
};

export default HeroSection;
