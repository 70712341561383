import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { useToast } from '../hooks/useToast';
import { blogConstants } from '../constants';

import TableOfContent from './TableOfContent';
import BlogSharing from './BlogSharing';

const PostContent = ({ content, tocHeadings, canonicalUrl }) => {
  const contentRef = useRef(null);
  const [isClient, setIsClient] = useState(false);
  const { addToast } = useToast();

  const handleCopyLink = async (hashLink) => {
    if (typeof window !== 'undefined') {
      try {
        const cleanUrl = new URL(canonicalUrl);

        const encodedHash = encodeURIComponent(`§${hashLink}`);
        const fullUrl = `${cleanUrl.origin}${cleanUrl.pathname}#${encodedHash}`;

        await navigator.clipboard.writeText(fullUrl);

        addToast('Link copied to clipboard!', 'success');
      } catch (err) {
        addToast('Failed to copy link', 'error');
      }
    }
  };

  useEffect(() => {
    setIsClient(true);
    if (contentRef.current) {
      const headers = contentRef.current.querySelectorAll(
        'h1, h2, h3, h4, h5, h6',
      );
      headers.forEach((header) => {
        const matchingTocEntry = tocHeadings.find(
          (toc) =>
            header.textContent === toc.title || header.id === toc.hashLink,
        );
        if (matchingTocEntry) {
          header.classList.add('has-link-icon');

          // We'll detect clicks only on the pseudo-element
          header.addEventListener('click', (e) => {
            const rect = header.getBoundingClientRect();
            const clickX = e.clientX - rect.left;
            const headerWidth = rect.width;

            if (clickX > headerWidth - 32) {
              e.stopPropagation();
              handleCopyLink(matchingTocEntry.hashLink);
            }
          });
        }
      });
    }
  }, [tocHeadings]);

  if (!isClient) {
    return (
      <div className="sr-only" dangerouslySetInnerHTML={{ __html: content }} />
    );
  }

  return (
    <div className="relative">
      <div
        className={`grid ${
          tocHeadings.length > 0
            ? blogConstants.grid.TocLayout
            : blogConstants.grid.TocLayout
        }`}
      >
        {tocHeadings.length > 0 && (
          <div className="hidden lg:block">
            <div className="sticky top-28">
              <TableOfContent tocHeadings={tocHeadings} />
              <BlogSharing canonicalUrl={canonicalUrl} />
            </div>
          </div>
        )}
        <div
          ref={contentRef}
          className={`w-full markdown blog md:p-0 ${
            tocHeadings.length === 0 ? 'lg:max-w-[968px] mx-auto' : ''
          }`}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </div>
  );
};

PostContent.propTypes = {
  content: PropTypes.string.isRequired,
  tocHeadings: PropTypes.array.isRequired,
  canonicalUrl: PropTypes.string.isRequired,
};

export default PostContent;
