import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import Layout from '../components/layout';
import SubscribeBlock from '../components/SubscribeBlock';
import BlogHighlights from '../components/BlogHighlights';
import { CommonContext } from '../context';
import MissionVisionSection from '../components/MissionVisionSection';
import AchievementsSection from '../components/AchievementsSection';
import OurValuesSection from '../components/OurValuesSection';
import MeetTheTeamSection from '../components/MeetTheTeamSection';
import TeamsSection from '../components/TeamsSection';
import JourneyStorySection from '../components/JourneyStorySection';
import AboutPageTopBanner from '../components/AboutPageTopBanner';

export const query = graphql`
  query MyQuery {
    allAchievements {
      nodes {
        icon
        title
        description
      }
    }
    allJourneyStory {
      nodes {
        description
        year
        title
        storyImage
      }
    }
    allTeamMembersData {
      nodes {
        id
        description
        image
        name
        role
        socialLinks {
          linkedIn
          twitter
        }
      }
    }
    allTeamsInformation {
      nodes {
        id
        title
        members
        icon
      }
    }
    allWpPost(sort: { fields: modified, order: DESC }, limit: 3) {
      nodes {
        slug
        title
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }
`;

function AboutPage({ data, location }) {
  const achievements = data.allAchievements.nodes;
  const journeyStories = data.allJourneyStory.nodes;
  const featuredBlogs = data.allWpPost.nodes;
  const teamMembers = data.allTeamMembersData.nodes;
  const teamsInformation = data.allTeamsInformation.nodes;

  return (
    <CommonContext.Provider value={{ location: location }}>
      <Layout
        keywords={[`greentech`, `islam`, `non-profit`, `muslims`]}
        title="About"
        location={location}
      >
        <AboutPageTopBanner />
        <MissionVisionSection />
        <AchievementsSection
          achievements={achievements}
          title="What We Have Achieved so Far"
          showDescription
        />
        <JourneyStorySection journeyStories={journeyStories} />
        <OurValuesSection />
        <MeetTheTeamSection teamMembers={teamMembers} />
        <TeamsSection teamsInformation={teamsInformation} />
        <BlogHighlights featuredBlogs={featuredBlogs} />
        <SubscribeBlock />
      </Layout>
    </CommonContext.Provider>
  );
}

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object,
};

export default AboutPage;
