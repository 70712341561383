import PropTypes from 'prop-types';
import React from 'react';
import { motion } from 'framer-motion';
import { cn } from '../../utils';

const Wave = ({ delay = 0, className }) => (
  <motion.div
    className={cn(
      'absolute inset-0 rounded-full bg-neutral-1/30 dark:bg-neutral-800/20',
      className,
    )}
    initial={{ scale: 0.8, opacity: 0 }}
    animate={{
      scale: [0.8, 1.75],
      opacity: [0, 0.3, 0],
    }}
    transition={{
      duration: 2.5,
      ease: 'easeInOut',
      times: [0, 0.5, 1],
      repeat: Infinity,
      delay,
    }}
  />
);

Wave.propTypes = {
  delay: PropTypes.number,
  className: PropTypes.string,
};

export default Wave;
