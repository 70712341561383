import React from 'react';
import PropTypes from 'prop-types';
import {
  Achievements,
  AchievementsDescription,
  AchievementsGrid,
  AchievementsItem,
  AchievementsTitle,
} from './ui/Achievement';

const AchievementsSection = ({
  achievements,
  title,
  showDescription = false,
}) => {
  return (
    <Achievements variant="dark">
      <div>
        <AchievementsTitle className="mb-16">
          <h3 className="text-3xl font-bold text-center md:text-5xl text-neutral sm:text-left">
            {title}
          </h3>
        </AchievementsTitle>
      </div>

      <AchievementsGrid className="md:my-0 px-[60px] md:px-0">
        {achievements.map((item) => (
          <div key={item.title}>
            <AchievementsItem
              icon={item.icon}
              title={item.title}
              description={item.description}
            />
          </div>
        ))}
      </AchievementsGrid>
      {showDescription && (
        <AchievementsDescription className="mt-8 text-neutral">
          Alhamdulillah, we now have 10+ apps, millions of users, and more than
          20 motivated team members. Alongside our favourite flagship app, Al
          Quran (Tafsir & by Word), we have apps for hadith Collection, Dua and
          Seerah. We&apos;re continually enhancing our apps to help Muslims get
          better every day.
        </AchievementsDescription>
      )}
    </Achievements>
  );
};

export default AchievementsSection;

AchievementsSection.propTypes = {
  achievements: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  showDescription: PropTypes.bool,
};
