import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '../../utils';
import IconComponent from '../IconComponent';

const VARIANTS = {
  light: {
    section: 'bg-primary-0',
    container: 'w-full max-w-6xl px-6 py-16 m-auto md:py-24',
    title: 'mb-8',
    grid: 'grid w-full grid-cols-1 gap-6 my-8 md:gap-8 sm:grid-cols-2 md:my-16 lg:grid-cols-4',
    description: 'w-full max-w-2xl m-auto text-sm text-center md:text-base',
    item: 'w-full rounded-2xl shadow-xl bg-neutral p-8 py-10 flex justify-center items-center lg:h-[252px]',
    itemContent: {
      icon: 'w-12 h-12 mb-5 mx-auto text-primary',
      title: 'text-xl font-bold text-center',
      description:
        'mt-2 text-sm leading-6 text-center max-w-[170px] min-h-[48px]',
    },
  },
  dark: {
    section: 'bg-neutral-9',
    container: 'w-full max-w-6xl px-6 sm:px-4 py-14 m-auto md:py-16',
    title: 'mb-8 text-white',
    grid: 'grid w-full grid-cols-1 gap-6 md:gap-6 lg:gap-8 sm:grid-cols-2 md:grid-cols-4 md:my-16 lg:grid-cols-4',
    description:
      'w-full max-w-2xl m-auto text-sm text-center md:text-base text-neutral-200',
    item: 'w-full rounded-2xl',
    itemContent: {
      icon: 'w-12 h-12 mb-5 text-primary',
      title: 'text-xl font-bold text-neutral text-start',
      description:
        'mt-2 text-sm leading-6 text-neutral text-start max-w-full min-h-[48px]',
    },
  },
};

const AchievementsContext = React.createContext({
  variant: 'light',
});

const Achievements = React.forwardRef(
  ({ children, className, variant = 'light', ...props }, ref) => {
    const styles = VARIANTS[variant];

    return (
      <AchievementsContext.Provider value={{ variant }}>
        <section ref={ref} className={cn(styles.section, className)} {...props}>
          <div className={styles.container}>{children}</div>
        </section>
      </AchievementsContext.Provider>
    );
  },
);

Achievements.displayName = 'Achievements';

const AchievementsTitle = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    const { variant } = React.useContext(AchievementsContext);
    const styles = VARIANTS[variant];

    return (
      <div ref={ref} className={cn(styles.title, className)} {...props}>
        {children}
      </div>
    );
  },
);

AchievementsTitle.displayName = 'AchievementsTitle';

const AchievementsGrid = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    const { variant } = React.useContext(AchievementsContext);
    const styles = VARIANTS[variant];

    return (
      <div ref={ref} className={cn(styles.grid, className)} {...props}>
        {children}
      </div>
    );
  },
);

AchievementsGrid.displayName = 'AchievementsGrid';

const AchievementsDescription = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    const { variant } = React.useContext(AchievementsContext);
    const styles = VARIANTS[variant];

    return (
      <div ref={ref} className={cn(styles.description, className)} {...props}>
        <p className="leading-7">{children}</p>
      </div>
    );
  },
);

AchievementsDescription.displayName = 'AchievementsDescription';

const AchievementsItem = React.forwardRef(
  ({ className, icon, title, description, ...props }, ref) => {
    const { variant } = React.useContext(AchievementsContext);
    const styles = VARIANTS[variant];

    return (
      <div ref={ref} className={cn(styles.item, className)} {...props}>
        <div
          className={cn(
            'h-max',
            variant === 'light' ? 'flex flex-col items-center w-full' : '',
          )}
        >
          <IconComponent name={icon} className={styles.itemContent.icon} />
          <div className={styles.itemContent.title}>{title}</div>
          <div className={styles.itemContent.description}>{description}</div>
        </div>
      </div>
    );
  },
);

AchievementsItem.displayName = 'AchievementsItem';

// PropTypes
const commonPropTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

Achievements.propTypes = {
  ...commonPropTypes,
  variant: PropTypes.oneOf(['light', 'dark']),
};

AchievementsTitle.propTypes = commonPropTypes;
AchievementsGrid.propTypes = commonPropTypes;
AchievementsDescription.propTypes = commonPropTypes;

AchievementsItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export {
  Achievements,
  AchievementsTitle,
  AchievementsGrid,
  AchievementsDescription,
  AchievementsItem,
};
