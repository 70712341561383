import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import BaseBlogsLayout, {
  PostPropType,
} from '../../../components/BaseBlogsLayout';
import DonateCallout from '../../../components/DonateCallout';
import { sortByCustomOrder } from '../../../utils';

export const query = graphql`
  query ($slug: String!) {
    wpCategory(slug: { eq: $slug }) {
      name
      slug
      description
      categoryBannerImages {
        bannerLinkUrl
        categoryBannerImage {
          altText
          gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
        }
      }
    }
    allWpCategory {
      nodes {
        slug
        name
      }
    }
    allWpPost(
      sort: { fields: modified, order: DESC }
      filter: {
        categories: { nodes: { elemMatch: { slug: { eq: $slug } } } }
        featuredImageId: { ne: null }
      }
    ) {
      nodes {
        slug
        title
        excerpt
        modified(formatString: "MMMM Do, YYYY")
        featuredImage {
          node {
            altText
            gatsbyImage(fit: COVER, width: 1000, placeholder: BLURRED)
          }
        }
        postMetadata {
          order
        }
        categories {
          nodes {
            slug
            name
          }
        }
      }
    }
  }
`;

const CategoryWiseBlogs = ({ data, location }) => {
  const category = data.wpCategory;
  const { bannerLinkUrl, categoryBannerImage } = category.categoryBannerImages;
  const allCategories = data.allWpCategory.nodes;

  const blogData = sortByCustomOrder(
    data.allWpPost.nodes,
    'postMetadata.order',
  );

  return (
    <BaseBlogsLayout
      title={`Category: ${category.name}`}
      description={category.description}
      location={location}
      blogData={blogData}
      categoryBannerImage={categoryBannerImage?.gatsbyImage}
      categoryBannerImageAltText={categoryBannerImage?.altText}
      bannerLinkUrl={bannerLinkUrl}
      activeCategory={category}
      allCategories={allCategories}
      pageTitle={category.name}
    >
      <section className="my-24">
        <DonateCallout />
      </section>
    </BaseBlogsLayout>
  );
};

CategoryWiseBlogs.propTypes = {
  data: PropTypes.shape({
    wpCategory: PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
      slug: PropTypes.string.isRequired,
      categoryBannerImages: PropTypes.shape({
        bannerLinkUrl: PropTypes.string,
        categoryBannerImage: PropTypes.shape({
          altText: PropTypes.string,
          gatsbyImage: PropTypes.object,
        }),
      }).isRequired,
    }).isRequired,
    allWpCategory: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        }),
      ).isRequired,
    }).isRequired,
    allWpPost: PropTypes.shape({
      nodes: PropTypes.arrayOf(PostPropType).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.object,
};

export default CategoryWiseBlogs;
